// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imageViewerViewport.empty ~ .ViewportOverlay {
  display: none;
}
.ViewportOverlay {
  color: #9ccef9;
}
.ViewportOverlay .overlay-element {
  position: absolute;
  font-weight: 400;
  text-shadow: 1px 1px #000;
  pointer-events: none;
}
.ViewportOverlay .top-left {
  top: 20px;
  left: 20px;
}
.ViewportOverlay .top-center {
  top: 20px;
  padding-top: 20px;
  width: 100%;
  text-align: center;
}
.ViewportOverlay .top-right {
  top: 20px;
  right: 20px;
  text-align: right;
}
.ViewportOverlay .bottom-left {
  bottom: 20px;
  left: 20px;
}
.ViewportOverlay .bottom-right {
  bottom: 20px;
  right: 20px;
  text-align: right;
}
.ViewportOverlay.controlsVisible .topright,
.ViewportOverlay.controlsVisible .bottomright {
  right: calc(20px + 19px);
}
.ViewportOverlay svg {
  color: #9ccef9;
  fill: #9ccef9;
  stroke: #9ccef9;
  background-color: transparent;
  margin: 2px;
  width: 18px;
  height: 18px;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/ViewportOverlay/ViewportOverlay.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;AACA;EACE,cAAc;AAChB;AACA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,yBAAyB;EACzB,oBAAoB;AACtB;AACA;EACE,SAAS;EACT,UAAU;AACZ;AACA;EACE,SAAS;EACT,iBAAiB;EACjB,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,SAAS;EACT,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,YAAY;EACZ,UAAU;AACZ;AACA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;AACnB;AACA;;EAEE,wBAAwB;AAC1B;AACA;EACE,cAAc;EACd,aAAa;EACb,eAAe;EACf,6BAA6B;EAC7B,WAAW;EACX,WAAW;EACX,YAAY;AACd","sourcesContent":[".imageViewerViewport.empty ~ .ViewportOverlay {\n  display: none;\n}\n.ViewportOverlay {\n  color: #9ccef9;\n}\n.ViewportOverlay .overlay-element {\n  position: absolute;\n  font-weight: 400;\n  text-shadow: 1px 1px #000;\n  pointer-events: none;\n}\n.ViewportOverlay .top-left {\n  top: 20px;\n  left: 20px;\n}\n.ViewportOverlay .top-center {\n  top: 20px;\n  padding-top: 20px;\n  width: 100%;\n  text-align: center;\n}\n.ViewportOverlay .top-right {\n  top: 20px;\n  right: 20px;\n  text-align: right;\n}\n.ViewportOverlay .bottom-left {\n  bottom: 20px;\n  left: 20px;\n}\n.ViewportOverlay .bottom-right {\n  bottom: 20px;\n  right: 20px;\n  text-align: right;\n}\n.ViewportOverlay.controlsVisible .topright,\n.ViewportOverlay.controlsVisible .bottomright {\n  right: calc(20px + 19px);\n}\n.ViewportOverlay svg {\n  color: #9ccef9;\n  fill: #9ccef9;\n  stroke: #9ccef9;\n  background-color: transparent;\n  margin: 2px;\n  width: 18px;\n  height: 18px;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;

// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `input[type='range'] {
  -webkit-appearance: none;
}

input[type='range']::-webkit-slider-thumb {
  -webkit-appearance: none;
  border-width: 2px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(9 12 41 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(90 204 230 / var(--tw-bg-opacity));
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

input[type='range']::-moz-range-thumb {
  border-width: 2px;
  border-style: solid;
  --tw-border-opacity: 1;
  border-color: rgb(9 12 41 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(90 204 230 / var(--tw-bg-opacity));
  height: 13px;
  width: 13px;
  border-radius: 50%;
}

input[type='range']::-webkit-slider-runnable-track {
  border: none !important;
}

input[type='range']::-moz-range-track {
  border: none !important;
}

.range-track {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
`, "",{"version":3,"sources":["webpack://./../../ui/src/components/InputRange/InputRange.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,wBAAwB;EACxB,iBAAsE;EAAtE,mBAAsE;EAAtE,sBAAsE;EAAtE,qDAAsE;EAAtE,kBAAsE;EAAtE,wDAAsE;EACtE,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;;AAGE;EAAA,iBAAsE;EAAtE,mBAAsE;EAAtE,sBAAsE;EAAtE,qDAAsE;EAAtE,kBAAsE;EAAtE,wDAAsE;EACtE,YAAY;EACZ,WAAW;EACX;AAHsE;;AAMxE;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;AACV","sourcesContent":["input[type='range'] {\n  -webkit-appearance: none;\n}\n\ninput[type='range']::-webkit-slider-thumb {\n  -webkit-appearance: none;\n  @apply border-primary-dark  bg-primary-light border-[2px] border-solid;\n  height: 13px;\n  width: 13px;\n  border-radius: 50%;\n}\n\ninput[type='range']::-moz-range-thumb {\n  @apply border-primary-dark  bg-primary-light border-[2px] border-solid;\n  height: 13px;\n  width: 13px;\n  border-radius: 50%;\n}\n\ninput[type='range']::-webkit-slider-runnable-track {\n  border: none !important;\n}\n\ninput[type='range']::-moz-range-track {\n  border: none !important;\n}\n\n.range-track {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
